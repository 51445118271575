.menu-button .icon .count {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    text-align: center;
    text-indent: 0;
    font-size: 1.2rem;
    top: -.2rem;
    &:after {
        position: absolute;
    }
}
.main-panel-block {
    & > .messages {
        display: flex;
        flex-direction: row;
        align-self: stretch;
        align-content: space-between;
        align-items: stretch;
        flex: auto;
        box-sizing: border-box;
        overflow: hidden;
        padding: 0;
        & .card {
            box-sizing: border-box;
            flex: auto;
            &.message-list {
                flex: none;
                box-sizing: border-box;
                width: 300px;
                overflow: hidden;
                margin-right: .5em;
                & > .card-body {
                    overflow: auto;
                }
                h5 {
                    position: relative;
                    text-align: center;
                    .message-add {
                        position: absolute;
                        right: 0;
                        top: .25rem;
                    }
                }
                .tile {
                    position: relative;
                    box-shadow: 0 0 0 .1rem rgba(67,67,67,.4);
                    border-radius: 1px;
                    margin-bottom: .5em;
                    padding: .25em;
                    cursor: pointer;
                    max-width: 100%;
                    &:after {
                        position: absolute;
                        right: -.7em;
                        top: 0
                    }
                    .tile-title {
                        font-weight: bold;
                    }
                    .tile-subtitle.text-gray {
                        display: flex;
                        font-size: .6rem;
                        .expand {
                            flex: auto;
                            transition: width linear 500ms;
                        }
                    }
                    h5.tile-title {
                        text-align: left;
                    }
                    .tile-action:before {
                        position: absolute;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        background-color: rgb(67,67,67,.4);
                        content: ' ';
                        width: 1.5em;
                    }
                }
                .thread-icon {
                    float: left;
                    width: 2em;
                    height: 2em;
                    & > .icon {
                        font-size: 1.6rem;
                        color: currentColor;
                        text-indent: initial;
                        text-align: center;
                    }
                }
            }
            &.message-thread {
                display: flex;
                flex: auto;
                box-sizing: border-box;
                overflow: hidden;
                & > .card-body {
                    display: flex;
                    flex-direction: row;
                    flex: auto;
                    overflow: auto;
                    & > * {
                        flex: auto;
                    }
                }
                .card.thread-list {
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    border-radius: 1px;
                    margin-bottom: .5em;
                    padding: .25em;
                    flex: auto;
                    border: none;
                    padding: 0;
                    & > .card-header {
                        padding: 0;
                        * {
                            font-weight: bold;
                        }
                    }
                    & > .card-body {
                        padding: 0;
                        flex: auto;
                        overflow: auto;
                        .tile-subtitle {
                            padding: 1em 2px;
                            width: 100%;
                            transition: background-color linear 1s 5s;
                            &:nth-child(odd) {
                                margin-top: .5em;
                                border-top-left-radius: 5px;
                                border-top-right-radius: 5px;
                            }
                            &:nth-child(even) {
                                border-bottom-left-radius: 5px;
                                border-bottom-right-radius: 5px;
                            }
                            &.unread {
                                background-color: rgba(67,67,67,.2);
                            }
                            &.sender {
                                font-weight: bold;
                                padding-left: .5em;
                                padding-bottom: .5em;
                            }
                            &.content {
                                padding: 0 0 1em 2em;
                                white-space: initial;
                            }
                        }
                    }
                    & > .card-footer {
                        display: flex;
                        padding: 0;
                        padding-top: 1em;

                        .input {
                            display: flex;
                            flex: auto;
                            position: relative;
                            align-self: flex-start;
                            & > input {
                                border: solid 1px darken(#43434366, 25%);
                                border-radius: 3px;
                                display: inline-block;
                                flex: auto;
                                height: 4em;
                                padding: 0 1em;
                                background-color: #43434333;
                            }
                        }
                    }
                    .tile-content {
                        display: flex;
                        flex-direction: column;
                        flex: auto;
                        align-self: stretch;
                        .scrollable {
                            flex: auto;
                            overflow: auto;
                        }
                        .tile-subtitle {
                            padding: 1em 2px;
                            width: 100%;
                            transition: background-color linear 1s 5s;
                            &:nth-child(odd) {
                                margin-top: .5em;
                                border-top-left-radius: 5px;
                                border-top-right-radius: 5px;
                            }
                            &:nth-child(even) {
                                border-bottom-left-radius: 5px;
                                border-bottom-right-radius: 5px;
                            }
                            &.unread {
                                background-color: rgba(67,67,67,.2);
                            }
                            &.sender {
                                font-weight: bold;
                                padding-left: .5em;
                                padding-bottom: .5em;
                            }
                            &.content {
                                padding: 0 0 1em 2em;
                                white-space: initial;
                            }
                            &.input {
                                position: relative;
                                align-self: flex-start;
                                display: flex;
                                & > .btn.icon {
                                        position: absolute;
                                        right: 0;
                                        align-self: center;
                                        font-size: 1.6rem;
                                        text-indent: 0;
                                        padding: 0;
                                        border: none;
                                        background-color: transparent;
                                    & > .icon {
                                        font-size: 1.6rem;
                                    }
                                }
                            }
                        }
                        .expand {
                            flex: auto;
                        }
                        .message-input {
                            flex: auto;
                            height: 3.2rem;
                            padding: 0 1.5em 0 1em;
                            background-color: #e4e4e4;
                            border-color: #979797;
                            border-radius: 3px;
                        }
                    }
                    .thread-icon {
                        float: left;
                        width: 2em;
                        height: 2em;
                        & > .icon {
                            font-size: 1.6rem;
                            color: currentColor;
                            text-indent: initial;
                            text-align: center;
                        }
                    }
                }
            }
        }
        .new.thread {
            & > .card-body {
                position: relative;
                & > .loading {
                    $loading-speed: 1s;
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    pointer-events: all;
                    background-color: #c0c0c0dd;
                    &::after {
                        animation: loading $loading-speed infinite linear;
                        width: 1.6rem;
                        height: 1.6rem;
                        margin-left: -.8rem;
                        margin-top: -.8rem;
                        border-width: .2rem;
                            border-top-color: transparent;
                            border-right-color: transparent;
                            border-left-color: rgb(87, 85, 217);
                            border-bottom-color: rgb(87, 85, 217);
                    }
                    &::before {
                        display: block;
                        animation: loading $loading-speed infinite linear;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        width: 3.2rem;
                        height: 3.2rem;
                        padding: 0;
                        margin-left: -1.6rem;
                        margin-top: -1.6rem;
                        background: 0 0;
                        border: .1rem solid #5755d9;
                            border-top-color: rgb(87, 85, 217);
                            border-right-color: rgb(87, 85, 217);
                            border-left-color: transparent;
                            border-bottom-color: transparent;
                        border-radius: 50%;
                        content: "";
                        opacity: 1;
                        z-index: 1;
                    }
                    & > .loading {
                        flex: auto;
                        &::after {
                            animation: loading $loading-speed infinite linear;
                            width: .8rem;
                            height: .8rem;
                            margin-left: -.4rem;
                            margin-top: -.4rem;
                                border-left-color: transparent;
                                border-bottom-color: transparent;
                                border-top-color: rgb(87, 85, 217);
                                border-right-color: rgb(87, 85, 217);
                        }
                        &::before {
                            display: block;
                            animation: loading $loading-speed infinite linear;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            width: 6.4rem;
                            height: 6.4rem;
                            padding: 0;
                            margin-left: -3.2rem;
                            margin-top: -3.2rem;
                            background: 0 0;
                            border: .2rem solid #5755d9;
                                border-left-color: rgb(87, 85, 217);
                                border-bottom-color: rgb(87, 85, 217);
                                border-top-color: transparent;
                                border-right-color: transparent;
                            border-radius: 50%;
                            content: "";
                            opacity: 1;
                            z-index: 1;
                        }
                    }
                }
                & > form {
                    display: flex;
                    flex-direction: column;
                    min-height: 60vh;
                    & > .message-recipients {
                        display: flex;
                        flex-direction: column;
                        flex: auto;
                    }
                    .multi-select {
                        display: flex;
                        flex: auto;
                        align-content: stretch;
                        height: 10em;
                        & > label {
                            display: block;
                            width: 100%;
                        }
                        .loading {
                            flex: auto;
                            width: 100%;
                        }
                        .panel {
                            flex: auto;
                            width: 100%;
                            align-self: stretch;
                        }
                        .pane {
                            display: flex;
                            flex-direction: column;
                            flex: auto;
                            width: 100%;
                            .menu {
                                z-index: initial;
                                height: 100%;
                                &[multiple] {
                                    overflow: auto;
                                }
                            }
                        }
                        select {
                            height: 100%;
                        }
                        .menu {
                            &.form-select {
                                display: block;
                                flex: auto;
                                align-self: stretch;
                                transform: none;
                                .menu-item {
                                    display: flex;
                                    align-items: center;
                                    text-align: justify;
                                    cursor: pointer;
                                    & > .item {
                                        flex: auto;
                                    }
                                    & > .icon:first-child {
                                        margin-right: .5em;
                                    }
                                    &:nth-of-type(odd) {
                                        background: #f7f8f9;
                                    }
                                }
                            }
                            box-shadow: none;
                            & > .menu-item {
                                text-align: center;
                                .btn {
                                    border: none;
                                    width: 100%;
                                }
                            }
                        }
                        & > * {
                            margin-left: 1em;
                            align-self: stretch;
                            &:first-child {
                                margin-left: 0;
                            }
                        }
                    }
                    & > .toolbar {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                        padding: 1em 0;
                        & > * {
                            margin-left: 1em;
                            min-width: 3.6rem;
                        }
                    }
                }
            }
            textarea {
                border: solid 1px darken(#43434366, 25%);
                border-radius: 3px;
                display: inline-block;
                flex: auto;
                height: 4em;
                padding: 1em;
                background-color: #43434333;
            }
        }
    }
}
.btn.icon {
    position: absolute;
    right: 0;
    height: 1.5em;
    align-self: center;
    text-indent: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    &.icon-2x {
        font-size: 1.6rem;
        & > .icon {
            font-size: 1.6rem;
        }
    }
}
@keyframes fade-in {
    from {opacity:0;}
    to {opacity:1;}
}
@keyframes fade-out {
    from {opacity:1;}
    to {opacity:0;}
}

@import '~common/scss/theme-base.scss';
$themes: ();
@import '~common/scss/theme/*.scss';

.profile-button {
    grid-column: 3/4;
    float: right;
}

.label-and-input {
    display: grid;
    grid-template-columns: 130px 1fr;
    margin: 6px 0;
}

.label-name {
    margin: auto 0;
    padding: 5px 0;
    font-weight: 500;
}

.datafield {
    margin: auto 0;
    padding: 0.3em 0;
}

.profile-card {
    margin: 1rem auto;
}

.card-icon {
    &.primary {
        @include themify($themes) {
            color: themed(primary-color-light);
        }
    }
    &.secondary {
        @include themify($themes) {
            color: themed(secondary-color-light);
        }
    }
    position: absolute;
    top: -27px;
    right: 0px;
    cursor: pointer;
    z-index: 2;
}

.profile-property {
    color: #686868;
    margin: 1em;
}

.module-header{
    margin-bottom: 1rem;
    h4 {
        cursor: default;
    }
    div {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }
    .add-button{
        cursor: pointer;
        margin-left: 1rem;
        padding: 7px;
        width: 100px;
        border: 1px solid #686868;
    }
}

div {
    .list-button.transparent-button {
        border: none;
        background-color: transparent;
        cursor: default;
        i {
            color: transparent;
        }
    }
    .list-button.transparent-button:hover {
        border: none;
        background-color: transparent;
        cursor: default;
    }
}

.add-button:hover{
    background-color: rgb(194, 194, 194);
    
}

.module-list-header {
    margin-top: 1.5rem;
    cursor: default;
    p {
        margin-bottom: 5px;
        font-weight: bold;
    }
    .name {
        margin-left: auto;
    }
    .status {
        margin-right: auto;
    }
}

.module-item {
    p {
        margin: 0;
    }
}

.divider.custom-divider {
    border-top-color: #cacaca;
    margin-top: 10px;
    margin-bottom: 10px;
}

.view-item{
    font-style: italic;
    p {
        margin-bottom: 0;
    }
}

.move-div {
    font-style: normal;
    margin: auto;
    button {
        cursor: pointer;
        border: none;
        background-color: transparent;
    }
    button:disabled {
        cursor: default;
    }
    .move-up {
        margin-right: .25rem;
    }
    .move-down {
        margin-left: .25rem;
    }
}
.article-img {
    width: 100%;
}
.bg-success {
    text-align: center;
    width: 160px;
    border-radius: 15px;
    margin: 10px 0;
}
.btn-sm {
    position: absolute;
}
#toastsContainer {
  width: 300px; 
  padding: 5px;
  position: fixed; 
  right: 5px; 
  top: 75px;
  z-index: 100000;  
  .toast {
    display: block;
    position: relative;
    margin-top: 7px;
    padding: 10px;
    border-radius: 15px; 
    text-align: center; 
    color: #fff; 
    background: #0083CB;
    z-index: 4;  
    p {
      margin: 0;
    }
    &.toast-error {
      background: #e73232;
    }
    &.toast-warning {
      background: #bf8630;
    }
    &.toast-success {
      background: #30BF85;
    }
  }
}
.col-content {
    flex: none !important;
    width: 87% !important;
}

.col-menu {
    flex: none !important;
    width: 13% !important;
}

ul li a {
    cursor: pointer;
}

ul{
    margin: 0 0 1em 0;
}

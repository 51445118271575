.body-content {
    label {
        cursor: pointer;
    }
    .contanct-info {
        text-align: center;
        label {
            display: block;
            margin: 0 auto;
        }
        button {
            display: block;
            margin: 1rem auto;
        }
    }
}
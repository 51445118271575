.color-option {
  outline: white;
    &.option-0 {
      background: #C285FF;
    }
    &.option-1 {
      background: #67C0EF;
    }
    &.option-2 {
      background: #FAABAB;
    }
    &.option-3 {
      background: #F380A0;
    }
    &.option-4 {
      background: #F67D7D;
    }
    &.option-5 {
      background: #FFC490;
    }
    &.option-6 {
      background: #FCEA99;
    }
    &.option-7 {
      background: #B3FFC2;
    }
    &.option-8 {
      background: #81EFC3;
    }
}


.profile-data{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
    margin: 1.5em auto;
}

.profile-header{
    margin: auto;
}

.profile-header-text{
    @include themify($themes) {
        color: themed(dark-neutral);
    }
}

.profile-card {
    padding: 0.5em;
    @include themify($themes) {
        border: 1px solid lightgrey;
    }
}

.card-icon{
    @include themify($themes) {
        color: themed(dark-neutral);
    }
    position: absolute;
    top: -27px;
    right: 0px;
    cursor: pointer;
    z-index: 2;
}

.account-property{
    margin: 1.5em 0 0 0;
    color: #686868;
}

.profile-property{
    margin: 1.5em 0 0 0;
    color: #686868;
    .property-data {
        margin: 0.25em 0;
        font-size: 14px;
    }
    .property-field{
        margin-top: 1em;
    }
}
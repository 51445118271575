@import '../../../node_modules/quill/dist/quill.snow.css';
@import '../../../node_modules/quill/dist/quill.core.css';

#file-input {
 display: none;
}

.file-input-icon {
    display: block;
    cursor: pointer;
    margin-top: 10px;
}

.file-input-icon:hover {
    fill: blue;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
    border: 1px solid #ccc;
}
.ql-toolbar.ql-snow {
    border-bottom: none;
}

.editor.ql-container {
    font-size: .8rem;
    &.ql-disabled {
        border: none;
        .ql-editor {
            padding: 0;
        }
    }
}
.edit-view-button {
    background: none;
    border: none;
    padding: 4px 8px;
    cursor: pointer;
    size
    &:hover {
        opacity: 0.8;
    }
    &:focus {
        outline: none;
    }
    &:disabled {
        cursor: default;
    }
    &.btn-icon {
        padding-top: 8px;
    }
}

.bottom-margin {
    margin-bottom: 15px;
}

.align-title {
    display: flex;
    flex-wrap: wrap;
    h4 {
        margin: 0;
    }
}

.card.card-component {
    &.no-padding {
        padding: 0;
    }
    border: none;
    .card-header {
        padding: 0;
    }
    .card-body {
        border: none;
        &.rounded-borders {
            border-radius: 15px;
        }
        &.all-border {
            border: 0.05rem solid rgb(218, 222, 228);
        }
        &.top-border {
            border-top: 0.05rem solid rgb(218, 222, 228);
        }
        &.left-border {
            border-left: 0.05rem solid rgb(218, 222, 228);
        }
        &.right-border {
            border-right: 0.05rem solid rgb(218, 222, 228);
        }
        &.bottom-border {
            border-bottom: 0.05rem solid rgb(218, 222, 228);
        }
    }
}

.component-toolbar {
    text-align: left;
    background: rgb(235, 235, 235);
    margin-bottom: 10px;
    padding: 4px 6px;
    border: gray solid 1px;
    color: black !important;
    .form-input.form-input-small {
        display: inline-block;
        text-align: center;
        width: 35px;
        background-color: #fff;
        opacity: 1;
        cursor: default;
    }

    .form-input.form-input-medium {
        display: inline-block;
        text-align: center;
        width: 70px;
        background-color: #fff;
        opacity: 1;
        cursor: default;
    }

    .divider-vert {
        display: inline;
        padding: 12px 10px;
        &::before {
            border-color: black;
        }
        &.add-toolbar {
            padding-top: 20px;
        }
    }

    .form-group {
        display: inline-block;
        &.form-group-margin {
            margin: 4px 8px;
        }
        label {
            display: inline-block;
            margin-right: 4px;
        }
        .form-input {
            cursor: pointer;
            width: 52px;
            padding: 0px;
        }
    }

    &.add {
        flex-basis: 100%;
    }
}

.toolbar-title {
    font-weight: bold;
}

.bottom-tracking {
    display: inline-block;
    position: absolute;
    bottom: 0;
    right: 0;
    p {
        display: inline-block;
        margin: auto 8px;
    }
}

.article-title {
    font-size: large;
}

.article-img {
    width: 100%;
    max-height: 300px;
    object-fit: contain;
}

.article-body {
    font-style: italic;
    margin: .5rem 0 0 0;
    vertical-align: middle;
    p {
        margin: 0 0 .6rem;;
    }
}

.article-button {
    text-align: right;
    margin: .5rem 0 0 0;
}

.article-modal {
    .modal-container {
        height: 100% !important;
        width: 100% !important;
        padding: 0;
        .modal-header {
            padding-bottom: 0;
        }
        .modal-body {
            height: 100% !important;
            width: 100% !important;
            .content {
                height: 100% !important;
                width: 100% !important;
                .article-frame {
                    width: 100% !important;
                    height: 100% !important;
                    height: auto;
                }
            }
        }
    }
}

.article-card {
    border: none !important;
    .card-image {
        img {
            width: 100%;
            object-fit: contain;
        }
    }
    .bg-success {
        text-align: center;
        width: 160px;
        border-radius: 15px;
    }
}

.accordion-editor-body {
    .form-label {
        padding: 0;
    }
    .divider {
        margin: 0.7rem 0 0.6rem 0;
        border-color: lightgray;
    }
}

.icon.icon-square {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 16px;
    height: 16px;
    border: 2px solid;
}

.icon.icon-rounded-square {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 16px;
    height: 16px;
    border: 2px solid;
    border-radius: 5px;
}

.icon.icon-half-square {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 16px;
    height: 16px;
    border: 2px solid;
    border-top: 0;
    border-left: 0;
}

.icon-anchor {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 2px;
    height: 14px;
    background: currentColor;
    border-radius: 8px;
    &::after, &::before {
        content: "";
        display: block;
        box-sizing: border-box;
        position: absolute;
        height: 6px;
        border: 2px solid;
    }
    &::after {
        width: 12px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        border-top-color: transparent;
        left: -5px;
        bottom: 2px;
    }
    &::before {
        width: 6px;
        border-radius: 8px;
        top: -4px;
        left: -2px;
    }
}
@mixin themify($themes) {
    @each $theme, $map in $themes {
        .theme-#{$theme} & {
            $theme-map: () !global;
            @each $key, $submap in $map {
                $value: map-get(map-get($themes, $theme), '#{$key}');
                $theme-map: map-merge($theme-map, ($key: $value)) !global;
            }

            @content;
            $theme-map: () !global;
        }
    }
}

@function themed($key, $default: $key) {
    $value: map-get($theme-map, $key);
    @if $value != null {
        @return $value;
    }
    @return map-get($theme-map, $default);
}

@function themedDarken($key, $percent, $default: $key) {
    $value: map-get($theme-map, $key);
    @if $value != null {
        @return darken($value, $percent);
    }
    $defaultValue: map-get($theme-map, $default);
    @if $defaultValue != null {
        @return darken($defaultValue, $percent);
    }
    @return $default;
}

@function themedLighten($key, $percent, $default: $key) {
    $value: map-get($theme-map, $key);
    @if $value != null {
        @return lighten($value, $percent);
    }
    $defaultValue: map-get($theme-map, $default);
    @if $defaultValue != null {
        @return lighten($defaultValue, $percent);
    }
    @return $default;
}

@function themedColor($key) {
    $value: map-get($theme-map, $key);
    @if $value != null {
        @return $value;
    }
    @return #000000;
}

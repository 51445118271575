.margin-bottom-header {
    margin-bottom: 15px;
}

.select-box {
    border: 1px solid black;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    height: 50vh;
}

ol .upper-list-item {
    display: list-item;
    list-style-type: disc;
    padding-left: 6px;
}

ol .sub-list-item {
    display: list-item;
    list-style-type: circle;
    padding-left: 6px;
}

.selectable {
    cursor: pointer;
}

.selected {
    background-color: rgba($color: #0083CB, $alpha: 0.3);
}

.center-button {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    .btn.btn-icon {
        background-color: transparent;
        border: none;
        margin: auto;
        font-size: 2rem;
        i::after {
            border: 0.175rem solid;
            border-bottom: 0;
            border-right: 0;
            border-left: 0;
        }
        i::before {
            border: 0.175rem solid;
            border-bottom: 0;
            border-right: 0;
        }
    }
    .btn.btn-icon:hover {
        background-color: transparent;
    }
    .btn.btn-icon:focus {
        box-shadow: none;
    }
}
.homePage {
    display: flex;
    flex-direction: column;
    flex: auto;
    height: 100%;
}

.change-lang {
    display: flex;
    align-items: center;
    justify-content: center;
    .btn-flag {
        // padding: 5px 8px !important;
        border: none;
        background: transparent;
        width: 45px;
        height: auto;
    }
}

div .btn-flag:focus {
    box-shadow: none;
}

.move-left {
    text-align: right !important;
}

header {
    height: 70px;
    div {
        text-align: center;
        // img {
        //     width: 60px;
        //     height: 60px;
        // }
    }
}
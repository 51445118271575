.btn-icon {
    background: none;
    border: none;
    padding: 4px 8px;
    cursor: pointer;
    size
    &:hover {
        opacity: 0.8;
    }
    &:focus {
        outline: none;
    }
}

.diagram-div {
    canvas {
        width: 99%;
    }
}

.divider.divider-custom {
    border-top-color: #cacaca !important;
    margin-top: 25px;
    margin-bottom: 12px;
}
.modal .modal-overlay {
  background: black !important;
  opacity: 0.6;
}
.table-modal {
  min-width: 300px;
  .modal-cell-label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: bold;
    font-size: 18px;
    &.in-modal-edit {
      margin-top: 0.5em;
    }
  }
}
.column-options{
  text-align: center;
  .form-radio {
    max-width: 150px;
  }
}

.confirm-buttons{
  margin: 0 auto;
  width: 12em;
  padding: 20px 0 35px 0;
}

.color-option.color-selection-button {
  width: 40px;
  margin: 0 0 0 0.2em;
  border: none;
}

.remove-option-button {
    width: 160px;
    margin: 0 0 0 0.5em;
}

li {
  list-style-type: none;
}
.header-input {
  margin: 1em 0;
  font-weight: bold;
  color: gray;
  font-weight: 500;
}
.add-option-button {
  margin: 0 0 0 0.3em;
  width: 10em;
}
.edit-header {
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  div{
    border: 0.1px solid lightgray;
    text-align: left;
    padding-left: 10px;
    color: gray;
    font-style: italic;
    font-weight: 500;
  }
}
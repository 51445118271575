/* COLORS & BUTTONS STYLING */

.blue-text{
    @include themify($themes) {
        color: themed(primary-color-light);;
    }
}

.orange-text{
    @include themify($themes) {
        color: themed(secondary-color-light);
    }
}

.green-text{
    @include themify($themes) {
        color: themed(tertiary-color-light);
    }
}

.blue-border{
    @include themify($themes) {
        border: 1px solid themed(primary-color-light);;
    }
}

.orange-border{
    @include themify($themes) {
        border: 1px solid themed(secondary-color-light);
    }
}

.green-border{
    @include themify($themes) {
        border: 1px solid themed(tertiary-color-light);
    }
}

.blue-button{
    @include themify($themes) {
        background-color: themed(primary-color-light);
        border: 1px solid themed(primary-color-light);
    }
}

.blue-button:hover{
    @include themify($themes) {
        background: themed(primary-color-dark);
        border: 1px solid themed(primary-color-dark);
        color: white;
    }
}

.orange-button{
    @include themify($themes) {
        background-color: themed(secondary-color-light);
        border: 1px solid themed(secondary-color-light);
    }
}

.orange-button:hover{
    @include themify($themes) {
        background: themed(secondary-color-dark);
        border: 1px solid themed(secondary-color-dark);
        color: white;
    }
}

.green-button{
    @include themify($themes) {
        background-color: themed(tertiary-color-light);
        border: 1px solid themed(tertiary-color-light);
    }
}

.green-button:hover{
    @include themify($themes) {
        background: themed(tertiary-color-dark);
        border: 1px solid themed(tertiary-color-dark);
        color: white;        
    }
}

.list-button{
    @include themify($themes) {
        background-color: themed(light-neutral);
        border: 1px solid themed(dark-neutral);
        color: themed(dark-gray);
        text-align: center;
        margin: 0 0 0 5px;
    }
}

.list-button:hover{
    @include themify($themes) {
        background-color: rgb(194, 194, 194);
    }
    cursor: pointer;
    outline: none;
}

button:hover{
    border: 1 px solid white;
}

.cancel-button, .save-button, .edit-button{
    float: right;
    color: white;
    border: none;
    outline: none;
    min-width: 5em;  
    height: 2.5em;
}

.cancel-button{
    @include themify($themes){
        margin-left: 5px;
        background-color: gray;
    }
}
.cancel-button:hover{
    cursor: pointer;
    @include themify($themes){
        background-color: themed(cancel-color);
    }
}

.save-button{
    @include themify($themes){  

        color:white;
        background-color: rgb(185, 185, 185);
    }
}

.save-button:hover{
    cursor: pointer;
    @include themify($themes){    
        background-color: themed(tertiary-color-dark);
        &.disabled{
            background-color: rgb(185, 185, 185);
        }
    }
}

.edit-button{
    @include themify($themes){     
        background-color: rgb(185, 185, 185);
    }
}

.edit-button:hover{
    cursor: pointer;
    @include themify($themes){     
        background-color: themed(primary-color-light);
    }
}

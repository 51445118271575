.side-menu-company {
    padding: 0.25rem;
    height: 100% !important;
    min-height: 100% !important;
    overflow-x: hidden;
    overflow-y: auto;
    .side-menu-ctitle {
        cursor: pointer;
        border-bottom: 1px solid lightgray;
        text-transform: uppercase;
        width: 100%;
        box-sizing: border-box;
        padding-top: 0.1rem;
        padding-bottom: 0.25rem;
        img {
            width: 60px;
            height: 60px;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
        h4 {
            text-align: center;
            font-weight: 500;
        }
    }
    .side-menu-module {
        display: block;
        margin: 1rem 0;
        .side-menu-mtitle {
            text-transform: uppercase;
            font-weight: 600;
            font-size: 17px;
            cursor: pointer;
            display: flex;
            flex-wrap: inherit;
            i {
                padding-right: 25px;
            }
            &.disabled {
                opacity: 0.8;
                cursor: default;
                .locked::after {
                    content: " (DEMO)";
                }
            }
        }
    }
    .side-menu-vtitle {
        word-break: break-all;
        cursor: pointer;
        font-size: 15px;
        list-style-type: circle;
        margin: .25rem 0 0 1.5rem;
    }
}

.company-grid {
    display: grid;
    grid-template-columns: 49% 49%;
    column-gap: 2%;
    row-gap: 10%;
    grid-template-rows: auto;
    text-align: center;
}

.company-grid .customer-card{
    border-radius: 15px;
}

.customer-card:hover{
    background-color: whitesmoke;
    cursor: pointer;
}

#lblpointer {
    cursor: pointer;
}

.switch-company-list{
    display: grid;
    min-width: 50%;
    max-width: 100%;
    grid-template-columns: 1fr 1fr;
    padding: 5px 0;
    border-bottom: 1px solid rgb(184, 184, 184);
    p {
        margin: 0;
        padding: 0 6px;
    }
    button {
        padding: 0 6px;

    }
    i {
        margin: 0 0 3px 0;
    }
}

.switch-company-list-header{
    border-top: 2px solid gray;
    padding-top: 10px;
    font-weight: 700;
}

.switch-company-list-item{
    cursor: pointer;
}

.switch-company-list-item:hover{
    cursor: pointer;
    background-color: rgb(228, 228, 228);
}

.company-text {
    position: absolute;
    bottom: 0px;
    margin: 0;
    font-style: italic;
}

.highlighted {
    text-decoration: underline;
}
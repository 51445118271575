.role-switcher {
    .form-select.form-select:not([multiple]):not([size]) {
        font-size: 1rem;
        font-weight: 700;
        max-width: fit-content;
        padding-right: 1.6rem;
        margin: 5px 0;
        border: none;
    }
    div {
        display: inline-block;
        font-size: 1rem;
        font-weight: 700;
        margin: 5px 0 !important;
        padding: 0.25rem 0;
    }
}
.main-content {
    .table {
        tbody {
            tr:hover {
                cursor: pointer;
                background-color: whitesmoke;
            }
        }
    }
}
.process-handling-toolbar {
    width: 100%;
    padding: 20px 15px;
    margin: 20px 0 0 0;
    background-color: whitesmoke;
    .form-group {
        .input-div {
            padding: 0 .5rem;
            .form-input {
                padding: .25rem;
            }
        }
    }
}

.add-issue-content {
    margin-top: 50px;
    padding: 0.8rem !important;
    background-color: rgb(144, 170, 255);
}

.data-block {
    margin-top: 50px;
    // padding: 0.8rem !important;
    // background-color: rgb(144, 170, 255);
}

.message-thread {
    border: none !important;
    display: flex;
    flex: auto;
    box-sizing: border-box;
    overflow: hidden;
    & > .card-body {
        display: flex;
        flex-direction: row;
        flex: auto;
        overflow: auto;
        & > * {
            flex: auto;
        }
    }
    .card.thread-list {
        display: flex;
        flex-direction: column;
        position: relative;
        border-radius: 1px;
        margin-bottom: .5em;
        padding: .25em;
        flex: auto;
        border: none;
        padding: 0;
        & > .card-header {
            padding: 0;
            * {
                font-weight: bold;
            }
        }
        & > .card-body {
            padding: 0;
            flex: auto;
            overflow: auto;
            .tile-subtitle {
                padding: 1em 2px;
                width: 100%;
                transition: background-color linear 1s 5s;
                &:nth-child(odd) {
                    margin-top: .5em;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                }
                &:nth-child(even) {
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
                &.unread {
                    background-color: rgba(67,67,67,.2);
                }
                &.sender {
                    font-weight: bold;
                    padding-left: .5em;
                    padding-bottom: .5em;
                }
                &.content {
                    padding: 0 0 1em 2em;
                    white-space: initial;
                }
            }
        }
        & > .card-footer {
            display: flex;
            padding: 0;
            padding-top: 1em;

            .input {
                display: flex;
                flex: auto;
                position: relative;
                align-self: flex-start;
                & > input {
                    border: solid 1px darken(#43434366, 25%);
                    border-radius: 3px;
                    display: inline-block;
                    flex: auto;
                    height: 4em;
                    padding: 0 1em;
                    background-color: #43434333;
                }
            }
        }
        .tile-content {
            display: flex;
            flex-direction: column;
            flex: auto;
            align-self: stretch;
            .scrollable {
                flex: auto;
                overflow: auto;
            }
            .tile-subtitle {
                padding: 1em 2px;
                width: 100%;
                transition: background-color linear 1s 5s;
                &:nth-child(odd) {
                    margin-top: .5em;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                }
                &:nth-child(even) {
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
                &.unread {
                    background-color: rgba(67,67,67,.2);
                }
                &.sender {
                    font-weight: bold;
                    padding-left: .5em;
                    padding-bottom: .5em;
                }
                &.content {
                    padding: 0 0 1em 2em;
                    white-space: initial;
                }
                &.input {
                    position: relative;
                    align-self: flex-start;
                    display: flex;
                    & > .btn.icon {
                            position: absolute;
                            right: 0;
                            align-self: center;
                            font-size: 1.6rem;
                            text-indent: 0;
                            padding: 0;
                            border: none;
                            background-color: transparent;
                        & > .icon {
                            font-size: 1.6rem;
                        }
                    }
                }
            }
            .expand {
                flex: auto;
            }
            .message-input {
                flex: auto;
                height: 3.2rem;
                padding: 0 1.5em 0 1em;
                background-color: #e4e4e4;
                border-color: #979797;
                border-radius: 3px;
            }
        }
        .thread-icon {
            float: left;
            width: 2em;
            height: 2em;
            & > .icon {
                font-size: 1.6rem;
                color: currentColor;
                text-indent: initial;
                text-align: center;
            }
        }
    }
}

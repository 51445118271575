.forum-topic-block {
    margin-top: .75rem;
    .card {
        cursor: pointer;
        &.card:hover {
            background-color: rgb(241, 241, 241);
        }
        .card-body {
            padding-top: 0;
            .body-text {
                font-size: .8em;
                padding: 0;
                margin: 0;
                display: block;
                &.last{
                    float: left;
                }
            }
        }
        .footer-text {    
            float: right;
            padding: 0 0 0.4rem 0 !important;
            opacity: .8;
            font-size: .8em;
            text-align: right;
        }
    }
}

.forum-board-block {
    .board-header {
        button {
            position: relative;
            display: inline-block;
        }
        h6 {
            position: relative;
            display: inline-block;
            margin: 0 0 0 .5rem;
        }
        padding: .25rem 0;
    }
    .board-table {
        tbody {
            tr {
                cursor: pointer;
            }
            tr:hover {
                background-color: rgb(241, 241, 241);
            }
        }
    }
}

.forum-thread-block {
    .thread-header {
        button {
            position: relative;
            display: inline-block;
        }
        h6 {
            position: relative;
            display: inline-block;
            margin: 0 0 0 .5rem;
        }
        padding: .25rem 0;
    }
    .post {
        .post-header {
            font-size: .8em;
        }
        .forum-thread-input {
            height: 100%;
            min-height: 75px;
            width: 100%;
        }
    }
}
@import '~common/scss/theme-base.scss';
$themes: ();
@import '~common/scss/theme/*.scss';

.table-view {
    display: inline-block;
    text-transform: uppercase;
    font-size: 18px;
    color: rgb(87, 87, 87);
    font-weight: bold;
    padding: 9px 0 0 0;
}
.modal-title {
    color:rgb(49, 49, 49);
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
    border-radius: 15px 15px 0 0;
    header {
        @include themify($themes) {
            background-color: themed(tertiary-color-light);
        }
    }
}
.table-container {
    .table-header{
        display: flex;
    }
    .table {
        max-height: 60vh;
        padding: 15px 0;
        &.in-edit {
            .main-table-header {
                font-size: 14px;
                padding: 5px;
            }
        }
        .main-table-header {
            font-weight: bold;
            font-size: 18px;
            text-align: center;
            border: 0.5px solid lightgray;
            input {
                max-width: 150px;
                margin: 0 auto 0 auto;
                text-align: center;
                font-weight: bold;
            }
            .edit-header-button {
                margin: 0 5px;
                font-weight: bold;
                color: gray;
                border: 0.1px solid lightgray;
                box-shadow: 0.1px 0.1px lightgray;
                border-radius: 5px;
            }
            &.borderless {
                border: none;
            }
            &.options {
                min-width: 150px;
            }
            &.freetext {
                min-width: 150px;
            }
            &.multi-options {
                min-width: 150px;
            }
            &.date {
                min-width: 150px;
            }
            &.file {
                min-width: 150px;
            }
            &.link {
                min-width: 125px;
            }
        }
        .ghost-td {
            border: none;
            background-color: white;
            &:hover {
                background: white;
            }
        }

        .move-remove-icons {
            padding: 0;
            text-align: center;
            .btn {
                position: relative;
                margin: 0 5px;
                color: gray;
                border: 0.1px solid lightgray;
                box-shadow: 0.1px 0.1px lightgray;
                border-radius: 5px;
                .icon {
                    top: 2px;
                    margin: 5px;
                    cursor: pointer;
                    &.icon-arrow-left {
                        margin: auto;
                    }
                    &.icon-delete {
                        margin: 3px;
                        &.delete-column {
                            padding: 0 1.8em;
                        }
                        &.delete-row {
                            padding: 0 0;
                        }
                    }
                    &.icon-arrow-right {
                        margin: auto;
                    }
                }
            }
        }

        .main-table-data {
            font-size: 16px;
            padding: 1px 5px;
            border: 0.5px solid lightgray;
            cursor: pointer;
        }

        .ghost-column {
            border-radius: 1px;
            box-shadow: 0px 0px 0px 0.5px rgba(0, 0, 0, 0.3);
        }

        .table-edit-button td:hover {
            background-color: none;
        }
    }
}
    .edit-table-button {
        background: none;
        border: none;
        height: 2.5em;
        padding: 5px 10px;
        &:hover {
            opacity: 0.8;
        }
        &:focus {
            outline: none;
        }
    }

.main-table-chip {
    align-items: center;
    border-radius: 5rem;
    display: inline-flex;
    font-size: 90%;
    line-height: 0.8rem;
    margin: 0.1rem;
    max-width: 320px;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
    &.active {
        @include themify($themes) {
            background: rgb(198, 202, 252);
            color: rgb(51, 51, 51);
        }
    }
    &.inactive {
        @include themify($themes) {
            background: rgb(211, 211, 211);
        }
    }
    &.yellow {
        background: rgb(252, 252, 165);
    }
    &.blue {
        background: rgb(194, 168, 255);
    }
    &.green {
        background: rgb(168, 255, 172);
    }
    &.orange {
        background: rgb(255, 225, 168);
    }
    &.gray {
        background: rgb(211, 211, 211);
    }
    &.brown {
        background: #b18762;
    }
}
.modal-footer {
    min-height: 3.5em;
}

.table-button {
    &.btn {
        border-radius: 15px;
    }
    float: right;
    min-height: 3.1em;
    min-width: 3.1em;
}

.btn.table-admin-button {
    background: #f7f8f9;
    color: gray;
    margin: 0 10px 0 0;
    font-weight: bold;
    border: 0.1px solid lightgray;
    box-shadow: 0.1px 0.1px lightgray;
    border-radius: 5px;
    &:hover {
        background: rgb(219, 219, 219);
        border: 0.1px solid white;
    }
}

div.transbox{
    background-color: #ffffff;
    opacity: 0.7;
    padding: 1px 0.4rem;
  }
  
  div.transbox p {
      margin: 0;
      color: black;
  }
  
  .popover-color-chip{
    cursor: pointer;
    outline: none;
    border: none;
    height: 2em;
    width: 2.5em;
    display: inline-flex;
    font-size: 90%;
    margin: 0.1rem;
    vertical-align: middle;
  }

  .selected-color{
      border: 2px solid rgb(117, 102, 102);
  }

  .eye{
      text-align: center;
      fill: rgb(109, 109, 109);
      margin: 0.4em 0 0 0;
  }
.input-type-file-label {
    cursor: pointer;
    display: block;
    i.icon {
        border-radius: 5px;
        margin: 5px;
        padding: 15px;
        border: 1px solid gray;
    }
}

.input-type-file {
    display: none;
}

button.tooltip.download-button{
    cursor: pointer;
    border: 0.5px solid gray;
    border-radius: 5px;
    margin: 3px;
    color: gray;
    background-color: white;
}

.checkbox-data {
    text-align: center;
    display: block;
    margin-right: auto;
    margin-left: auto;
}
.form-label.tooltip{
    padding: 0 0 6px 0;
}
.table-modal {
    .modal-container {
        .modal-body {
            min-height: 300px;
        }
    }
    .modal-cell-label{
        margin: 0;
        padding: 0;
        font-weight: bold;
        font-size: 18px;
        &.in-modal-edit {
            margin-top: 0.5em;
        }
    }
}
div.modal-container .modal-footer{
    padding: 0;
}
label.form-label.tooltip::after{
    transform: translate(-75px, 0.2rem);
}
.form-label.tooltip.in-modal-edit::after{
    transform: translate(-75px, 0.2rem);
}
.multiselect-block {
    width: 100%;
    min-width: 5vw;
    position: relative;
}

#btn-toggle {
    width: 100%;
    background-color: white;
    white-space: nowrap !important;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chosen-item{
    padding: 0 5px;
}

#multiselect-options {
    width: 100%;
    position: absolute;
    z-index: 5;
    height: 100px;
    overflow-y: scroll;
    background-color: white;
    padding: 10px 15px 0 15px;
    margin: 0;
    border: 1px solid blue;
    input {
        margin: 6px 0 0 0;
        padding: 0;
    }
    p {
        display: inline-block;
        padding: 0 0 0 10px;
        margin: 0 0 5px 15px;
        max-width: 100%;
    }
}